import { openShoppingListDialog, showAddToListMsg } from "./shoppinglist";

/********************************
 * NOTE: "CART_TYPE", "CART_PK" *
 ********************************/

/*
Originally this file was only used with the cart.
It now supports use with shopping lists as well.
In order to distinguish between the two, data-cart-type and
data-cart-pk attributes have been added to the button widgets,
and are passed around by the Javascript as cart_type and cart_pk.
*/


/********************
 * GLOBAL VARIABLES *
 ********************/
/* For cart update alerts. */
var alertTimeout;

/* for use with refreshCartHTML */
var refreshTimer;
var refreshTimerDelay = 200;

/* use decimals (new style)? If not, uses integers (old style) */
var USE_DECIMAL_CART_QUANTITIES = Boolean(window.USE_DECIMAL_CART_QUANTITIES);

/* decimal places in product quantities */
var QUANTITY_PRECISION = 3;


/****************
 * CONSOLE CART *
 ****************/

export function ConsoleCart() {
    /*
        "Shop from the comfort of your console!"

        Allows cart to be used without having to click things.
        Example:

            // adds 3 bananas to the cart
            console_cart.add_product('4011-1', 3);

            // find the quantity buttons for bananas in the cart
            console_cart.get_quantity_btns('4011-1', null, null);

            // find the quantity buttons for all items in the
            // ShoppingList with id 3
            console_cart.get_quantity_btns(undefined, 'shoppinglist', 3);

    */

    this.bindCartWidgets = bindCartWidgets;
    this.refreshCartHTML = refreshCartHTML;
    this.get_quantity_btns = get_quantity_btns;
    this.update_quantity_btns = update_quantity_btns;
    this.update_quantity_btns_additive = update_quantity_btns_additive;
    this.add_product = add_product;
}




/*********************
 * UTILITY FUNCTIONS *
 *********************/

function get_item_jfilter(unique_key, cart_type, cart_pk) {
    /* Resturns a string to be used by jQuery to filter for DOM elements
    related to a particular cart item.
    The magic unique_key prefix 'id-' is used to indicate that we want to
    use the item's "id_within_container".

    Using a magic prefix is kind of gross, but this file was written
    back when unique_key was the only way to identify items, so all these
    functions only take a unique_key parameter. [shrugs] */

    var jfilter;
    if (typeof unique_key === 'undefined') {
        /* Find all quantity buttons for the given cart_type, cart_pk */
        jfilter = '';
    } else if (unique_key.indexOf('id-') === 0) {
        /* Filter by id_within_container */
        var id_within_container = unique_key.substr('id-'.length);
        jfilter = '[data-id-within-container="' + id_within_container + '"]';
    } else {
        /* Filter by unique_key */
        jfilter = '[data-unique-key="' + unique_key + '"]';
    }

    if (cart_type === null) {
        /* Filter for no cart_type; e.g. the minicart's quantity buttons
        were implemented before cart_type, cart_pk existed, so they don't
        use them. */
        jfilter += ':not([data-cart-type])';
    } else if (typeof cart_type !== 'undefined') {
        /* Filter by cart_type, e.g. 'cart', 'shoppinglist' */
        jfilter += '[data-cart-type="' + cart_type + '"]';
    }

    if (cart_pk === null) {
        /* Filter for no cart_pk; e.g. the minicart's quantity buttons
        were implemented before cart_type, cart_pk existed, so they don't
        use them. */
        jfilter += ':not([data-cart-pk])';
    } else if (typeof cart_pk !== 'undefined') {
        /* Filter by cart_pk, e.g. 3 */
        jfilter += '[data-cart-pk="' + cart_pk + '"]';
    }

    return jfilter;
}

function alreadyBound(elem, event_name) {
    if (!!elem.get) {
        var pvt = $._data(elem.get(0), "events");
    } else {
        var pvt = $._data(elem, "events");
    }

    if (pvt === undefined) {
        return false;
    } else {
        var pvt_arr = pvt[event_name];
        if (pvt_arr === undefined || pvt_arr.length === 0) {
            return false;
        } else {
            return true;
        }
    }
}

function getObjSize(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) {
            size++;
        }
    }
    return size;
}

function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

function parseNumeric(n) {
    var parsed_n = parseFloat(n);
    if (isNaN(parsed_n) || !isFinite(parsed_n)) return NaN;
    return parsed_n;
}

function parseQuantity(n) {
    if (USE_DECIMAL_CART_QUANTITIES) {
        return round(parseNumeric(n), QUANTITY_PRECISION);
    } else {
        return parseInt(n);
    }
}



/***************
 * SERIAL CART *
 ***************/

/* The "serial cart" is a lightweight JSON serialization of the cart.
It's faster and cleaner than having the server re-render the cart
HTML (see refreshCartHTML, jamCartHTMLIntoDOM, etc).
Perhaps we can move towards using it.
The biggest hurdle is dealing with the case when a new item is added
to the cart, whose product didn't exist anywhere on the page before. */

function serial_cart_get_item_by_id_within_container(serial_cart,
    id_within_container
) {
    var items = serial_cart.items;
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.id_within_container == id_within_container) {
            return item;
        }
    }
    return null;
}

function serial_cart_get_unique_key_counts(serial_cart) {
    var unique_key_counts = {};

    /* Count the # of occurrences of each unique_key */
    var items = serial_cart.items;
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var unique_key = item.unique_key;
        unique_key_counts[unique_key] = (unique_key_counts[unique_key] || 0) + 1;
    }
    return unique_key_counts;
}

export function render_serial_cart(serial_cart) {
    var unique_key_counts = serial_cart_get_unique_key_counts(serial_cart);

    /* Update quantity buttons */
    var items = serial_cart.items;
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var unique_key = item.unique_key;

        if (unique_key_counts[unique_key] > 1) {
            /* Multiple items with same unique_key: items are probably
            packs, and show 'Edit' buttons instead of '+/-' buttons.
            In any case, update each item individually.
            This is basically just to handle the case where a pack item's
            quantity was set to 0.
            The reason we don't just use id_within_container for all items
            is that the '+/-' buttons on product thumbnails can only be
            found by unique_key.
            But products which are packs don't show those, they show an
            'Add to cart' button which is not affected by quantity, so
            it's fine. */
            unique_key = 'id-' + item.id_within_container;
        }

        update_quantity_btns(unique_key, parseQuantity(item.quantity));
    }
}

function pushToAnalytics(data, xhr) {
    var addProducts = [];
    var removeProducts = [];
    for (var key in data) {
        if (key === "mode") continue;
        if (data[key] >= 1) {
            addProducts.push(
                {
                    'id': key,
                    'quantity': data[key]
                }
            );
        } else {
            removeProducts.push(
                {
                    'id': key,
                    'quantity': Math.abs(data[key])
                }
            );
        }
    }
    if (addProducts.length) {
        dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'add': {
                    'products': addProducts
                }
            }
        });
    }
    if (removeProducts.length) {
        dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
                'remove': {
                    'products': removeProducts
                }
            }
        });
    }
}

/******************
 * AJAX ENDPOINTS *
 ******************/

function cart_merge(other_cart_type, other_cart_pk, cart_type, cart_pk) {
    /*
        Merges another order in the cart via AJAX
        (Endpoint: cart_merge_endpoint)
    */

    var csrftoken = window.csrf_token;

    var data = {};
    data.other_cart_type = other_cart_type;
    data.other_cart_pk = other_cart_pk;
    data.cart_type = cart_type;
    data.cart_pk = cart_pk;

    var request = $.ajax({
        url: cart_merge_endpoint,
        type: "POST",
        headers: { 'X-CSRFToken': csrftoken },
        data: data,
        success: function (data) {
            window.serial_cart = data.serial_cart;
            render_serial_cart(data.serial_cart);
            var callback = function () {
                bulk_add_btn_popover("All items added to the cart!");
            };
            refreshCartHTML(callback);
        },
        error: function (response) {
            var callback = function () {
                bulk_add_btn_popover("Could not add all items to the cart",
                    "Could not add items", 'danger');
            };
            refreshCartHTML(callback);
            console.log("Cart merge failed", response);
        }
    });

    return request;
}

function patch_item(id_within_container, cart_type, cart_pk, data) {
    /*
        Updates fields of a single cart item via AJAX
        (Endpoint: patch_item_endpoint)

        data: object mapping fields of OrderItem to their patched values
    */

    var csrftoken = window.csrf_token;

    data.id_within_container = id_within_container;
    data.cart_type = cart_type;
    data.cart_pk = cart_pk;

    var request = $.ajaxq("MightyQ:patch_item", {
        url: patch_item_endpoint,
        type: "POST",
        headers: { 'X-CSRFToken': csrftoken },
        data: data
    });

    return request;
}

function cart_add(data) {
    /*
        Updates multiple cart items via AJAX
        (Endpoint: cart_add_endpoint)

        data looks like:

            {
                'mode': 'JSON',
                '4011-1': 3,
                '4013-1': -1,
                '4015-1': 12
            }
    */

    var csrftoken = window.csrf_token;

    data['mode'] = 'JSON';
    var request = $.ajaxq("MightyQ", {
        url: cart_add_endpoint,
        type: "POST",
        headers: { 'X-CSRFToken': csrftoken },
        complete: function (xhr, status) {
            if (status === "success") {
                pushToAnalytics(data, xhr);
            }
        },
        data: data
    });
    return request;
}

function getRenderedCarts(urlParams) {
    /*
        Server re-renders various representations of the cart, e.g.
        main cart, mini cart, side cart
        (Endpoint: cartUrl)
    */
    urlParams = urlParams || "";
    return $.getq("MightyQ", cartUrl + urlParams);
}

function postRenderedCarts(urlParams, data) {
    /*
        Server re-renders various representations of the cart, e.g.
        main cart, mini cart, side cart
        (Endpoint: cartUrl)
    */
    urlParams = urlParams || "";
    data = data || {};
    return $.postq("MightyQ", cartUrl + urlParams, data);
}




/*****************************
 * GETTING DATA FROM WIDGETS *
 *****************************/

function get_bulk_add_data(bulk_add_btn) {
    var data = {};
    $(bulk_add_btn).siblings('.bulk-add-input').each(function (j, inpt) {
        var unique_key = $(inpt).attr('name');

        var quantity = $(inpt).val();
        if (!data.hasOwnProperty(unique_key)) data[unique_key] = 0;
        data[unique_key] += parseQuantity(quantity);

        var note = $(inpt).attr('data-item-note');
        if (note !== undefined) data['note-' + unique_key] = note;

        var substitute = $(inpt).attr('data-item-substitute'); // 'y' or 'n'
        if (substitute !== undefined) data['substitute-' + unique_key] = substitute;
    });
    return data;
}




/*********************************
 * CREATING AND UPDATING WIDGETS *
 *********************************/

function hide_elements(elements, hidden) {
    if (hidden) elements.addClass("d-none");
    else elements.removeClass("d-none");
}

function createErrorPopover(msg_level) {
    /*
        Creates & returns a popover for showing AJAX results
        msg_level: 'success', 'warning', or 'danger'
    */
    var popover = $('<div>', { class: "popover popover-" + msg_level })
        .append($('<div>', { class: "arrow" }))
        .append(
            $('<button>', {
                'aria-hidden': "true",
                'data-dismiss': "alert",
                class: "close",
                type: "button"
            })
                .html($("<i>", { class: 'fa fa-times-circle' })))
        .append($('<h3>', { class: "popover-title" }))
        .append($('<div>', { class: "popover-content" }));

    return popover;
}

function create_empty_cart_dialog() {
    var $empty_cart_dialog = $(
        ""
        + "<div class='mc-empty_cart-dialog' title='Confirmation'>"
        + "    <p>"
        + "        Are you sure you want to empty the cart?"
        + "    </p>"
        + "</div>"
    ).dialog({
        autoOpen: false,
        //width: 400,
        buttons: [
            {
                text: "Ok",
                click: function () {
                    empty_cart();
                    $(this).dialog("close");
                }
            },
            {
                text: "Cancel",
                click: function () {
                    $(this).dialog("close");
                }
            }
        ]
    });
    $empty_cart_dialog.find('.mc-dialog-ok').on('click', function (event) {
        $empty_cart_dialog.dialog("close");
    });
    $empty_cart_dialog.find('.mc-dialog-cancel').on('click', function (event) {
        $empty_cart_dialog.dialog("close");
    });

    return $empty_cart_dialog;
}

function open_empty_cart_dialog() {
    // close any already-open dialogs
    $('.mc-empty_cart-dialog').each(function (i, elem) {
        try {
            $(elem).dialog("close");
        } catch (e) {
            // Sometimes this fails with 'Error: cannot call methods on dialog prior to initialization'
            console.log(e);
        }
    });

    // create & open a new dialog
    var $empty_cart_dialog = create_empty_cart_dialog();
    $empty_cart_dialog.dialog("open");
}


const ADDING = 'ADDING';
const UPDATING = 'UPDATING';
const REMOVING = 'REMOVING';
function getCartAlertSuffixText(action, isShoppingList) {
    // TODO: make all of these text values translated settings in django.
    // NOTE: Using the wishlist instead of standard "list" verbage since Murchie's
    // wants it. TODO: Make WISHLIST_TEXT setting.
    const cartText = isShoppingList ? "wishlist" : "cart";
    switch (action) {
        case ADDING:
            return "was added to your " + cartText + ".";
        case UPDATING:
            return "was updated in your " + cartText + ".";
        case REMOVING:
            return "was removed from your " + cartText + ".";
        default:
            return "";
    }
}

function showCartAlert(action, productTitle, isShoppingList, cb) {
    if (typeof isShoppingList === 'undefined') isShoppingList = false;
    if (typeof productTitle === 'undefined') productTitle = "";
    if (typeof alertTimeout !== 'undefined') clearTimeout(alertTimeout);
    // This won't be available if the setting for cart alerts is disabled.
    var $alert = $('#cart-alert');
    // Duck out!
    if ($alert.length === 0) return;
    $alert.find(".cart-alert-product-title").html(productTitle);
    $alert.find(".cart-alert-suffix").html(
        getCartAlertSuffixText(action, isShoppingList)
    );
    $alert.show('fade');
    alertTimeout = setTimeout(function () { $alert.hide('fade'); }, 4000);
    if (typeof cb !== 'undefined') return cb();
    return;
}

function bindCartWidgets() {
    /*
        On page load, and whenever the minicart's HTML is reloaded via AJAX, this
        function is called and attaches various listeners to the cart's widgets.
        It does !alreadyBound() checks first, since some widgets exist outside of
        the minicart's HTML, and therefore already had the appropriate events
        attached by this function on page load.
    */

    $('.mc-empty_cart-btn').each(function (i, elem) {
        if (!alreadyBound(elem, 'click')) {
            $(elem).on('click', function (event) {
                event.preventDefault();
                open_empty_cart_dialog();
            });
        }
    });

    $('.bulk-add-btn').each(function (i, elem) {
        if (!alreadyBound(elem, 'click')) {
            $(elem).on('click', function (event) {
                event.preventDefault();

                var other_cart_type = $(elem).attr('data-other-cart-type');
                var other_cart_pk = $(elem).attr('data-other-cart-pk');

                /* Backwards compatibility check: older templates didn't
                have data-other-cart-type */
                if (other_cart_type) {
                    cart_merge(other_cart_type, other_cart_pk);
                } else {
                    /* The Old Way of doing bulk adds, doesn't work with
                    some newer features like item addons. */
                    var data = get_bulk_add_data(elem);
                    productPost(data);
                }

                /* used to tell refreshCartHTML to stick a popover <div>
                over this button with AJAX success/error info */
                $(elem).addClass('clicked');
            });
        }
    });

    $('.add-btn').each(function (i, elem) {
        if (!alreadyBound(elem, 'click')) {
            $(elem).on('click', function (event) {
                event.preventDefault();
                var $btn = $(this);

                var unique_key = $btn.attr('data-unique-key');
                var cart_type = $btn.attr('data-cart-type');
                var cart_pk = $btn.attr('data-cart-pk');
                var product_title = $btn.attr('data-product-title');
                // 1 || -1 || NaN (When adding).
                var add_quantity = parseQuantity($btn.val());
                var int_add_quantity = parseInt(add_quantity);
                // Grab the quantity before modifications.
                // Number | Decimal | NaN (When adding)
                var curr_quantity = parseInt($btn.siblings(".quantity-btn").text());
                var isAdding = (typeof curr_quantity === 'NaN' || curr_quantity === 0) && int_add_quantity === 1;
                var isShoppingList = cart_type === 'shoppinglist';
                var hasCartAlert = $btn.hasClass("cart-alert");
                var isDecreasing = int_add_quantity < 0;
                var isRemoving = (curr_quantity === 0 && int_add_quantity === -1) || (isDecreasing && curr_quantity === 1);
                var isUpdating = $btn.hasClass("btn-update") && !isRemoving;
                var isPack = $btn.attr('data-is-pack') === "true";
                var note = $btn.data("note");
                var action = isUpdating ? isAdding ? ADDING : UPDATING : isRemoving ? REMOVING : ADDING;

                if (!isPack) {
                    var request = add_product(unique_key, add_quantity, cart_type, cart_pk, note);

                    if ($btn.hasClass('add-to-list-btn')) {
                        /* This button was an "Add to List" button (for shopping lists) */
                        request.done(function (xhr) {
                            showAddToListMsg(unique_key, 'thumbnail', false);
                        }).fail(function (xhr) {
                            if (xhr.status === 404) {
                                /* If adding to cart returns 404, it probably
                                means that the user doesn't have any shopping lists yet.
                                So we open a dialog asking them to create a new list. */
                                openShoppingListDialog(unique_key);
                            }
                        });
                    } else {
                        request.done(function (xhr) {
                            // TODO: Update text based on whether it's a shopping list
                            //    and shopping lists are supported!
                            hasCartAlert && showCartAlert(
                                action, product_title, isShoppingList
                            );
                        });
                    }
                } else {
                    if ($btn.hasClass('add-to-list-btn')) {
                        var hasList = $btn.attr('data-has-list') == "true";
                        if (hasList) {
                            window.location = $btn.attr("href");
                        } else {
                            openShoppingListDialog(unique_key);
                        }
                    }
                }
            });
        }
    });


    $('.del-btn').each(function (i, elem) {
        if (!alreadyBound(elem, 'click')) {
            $(elem).on('click', function (event) {
                event.preventDefault();
                var id_within_container = $(this).attr('data-id-within-container');
                var unique_key = $(this).attr('data-unique-key');
                var cart_type = $(this).attr('data-cart-type');
                var cart_pk = $(this).attr('data-cart-pk');
                delete_item(id_within_container, unique_key, cart_type, cart_pk);
            });
        }
    });

    $('.note-btn').each(function (i, elem) {
        if (!alreadyBound(elem, 'click')) {
            var note_btn = $(elem);
            bind_note_widget(note_btn);
        }
    });

    $('.allow-all-subs').each(function (i, elem) {
        if (!alreadyBound(elem, 'click')) {
            var cart_type = $(elem).attr('data-cart-type');
            var cart_pk = $(elem).attr('data-cart-pk');
            $(elem).on('click', function (event) {
                allow_all_subs(true, cart_type, cart_pk);
            });
        }
    });

    $('.disallow-all-subs').each(function (i, elem) {
        if (!alreadyBound(elem, 'click')) {
            var cart_type = $(elem).attr('data-cart-type');
            var cart_pk = $(elem).attr('data-cart-pk');
            $(elem).on('click', function (event) {
                allow_all_subs(false, cart_type, cart_pk);
            });
        }
    });

    $('.quantity-btn').each(function (i, elem) {
        if (!alreadyBound(elem, 'click')) {
            $(elem).on('click', function (e) {
                create_quantity_inpt($(this));
            });
        }
    });

}

function create_quantity_inpt(qtyBtn) {
    if (!(qtyBtn.hasClass('edit-quantity'))) {
        qtyBtn.addClass('edit-quantity');

        var inpt = $('<input>', { type: 'number', style: 'width: 100%;' });
        var old_quantity = qtyBtn.html();
        var btnGroup = qtyBtn.closest('.btn-group');

        var cart_type = qtyBtn.attr('data-cart-type');
        var cart_pk = qtyBtn.attr('data-cart-pk');

        btnGroup.css('width', btnGroup.width());
        qtyBtn.html(inpt);
        qtyBtn.siblings('.btn').hide();
        //qtyBtn.css('width', '100%');

        $(inpt)
            .focus()
            .val(old_quantity)
            .select();

        $(inpt).focusout(function (e) {
            save_quantity_inpt($(e.target), old_quantity, cart_type, cart_pk);
        });

        $(inpt).keydown(function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                save_quantity_inpt($(e.target), old_quantity, cart_type, cart_pk);
            }
        });
    }
}

function update_quantity_btn(qtyBtn, new_quantity) {
    /* updates quantity displayed on quantity_btn, and also possibly
    decides which form is visible: the spinner-form (which contains the
    "+/-" add-btns and this quantity-btn) or the single-form (which
    contains one big "Add to Cart" add-btn) */

    var spinner_form = qtyBtn.closest('.spinner-form');
    if (new_quantity > 0) {
        qtyBtn.html(new_quantity);

        spinner_form.removeClass('d-none');
        spinner_form.siblings('.single-form').addClass('d-none');
    } else {
        qtyBtn.html(0);

        spinner_form.addClass('d-none');
        spinner_form.siblings('.single-form').removeClass('d-none');
    }
    $(qtyBtn).trigger('qtyBtnUpdateEvent');
}

function get_quantity_btns(uniqueKey, cart_type, cart_pk) {
    var jfilter = get_item_jfilter(uniqueKey, cart_type, cart_pk);
    return $('.quantity-btn' + jfilter);
}

function update_quantity_btns_additive(uniqueKey, add_quantity, cart_type, cart_pk) {
    let $btns = get_quantity_btns(uniqueKey, cart_type, cart_pk);
    $btns.each(function (i, elem) {
        var qtyBtn = $(elem);
        var old_quantity = parseQuantity(qtyBtn.html());
        var new_quantity = parseQuantity(old_quantity + add_quantity);
        update_quantity_btn(qtyBtn, new_quantity);
    });
}

function update_quantity_btns(uniqueKey, new_quantity, cart_type, cart_pk) {
    let $btns = get_quantity_btns(uniqueKey, cart_type, cart_pk);
    $btns.each(function (i, elem) {
        var qtyBtn = $(elem);
        update_quantity_btn(qtyBtn, parseQuantity(new_quantity));
    });
}

function attachErrorPopover(elem, error_msg) {
    elem.effect("shake", function () {
        var container = undefined;
        if ($(this).parents('.mini-cart').length !== 0) {
            container = '.mini-cart';
        }

        $(this)
            .popover({
                template: createErrorPopover('danger')
                    .prop('outerHTML'),
                title: "Error",
                content: error_msg,
                container: container,
                placement: "top",
                trigger: "focus"
            })
            .focus()
            .mouseout(function () {
                $(this).popover('destroy');
            });
    });
}

function roll_back_quantity_btns(uniqueKey, quantity_revert, error_msg) {
    /*
        When user modifies a quantity in their cart (e.g. adds some of a
        given product), two things happen:
            1. widgets are immediately updated with new quantity
            2. an AJAX call to cart_add_endpoint is made
        If the AJAX call fails, this function is used to "roll back" the
        updated widgets to show their original quantities.

        The 'quantity_revert' parameter comes straight from the server,
        and is the current quantity in the cart.
    */

    var jfilter = get_item_jfilter(uniqueKey);
    $('.quantity-btn' + jfilter).each(function (j, elem) {
        var qtyBtn = $(elem);
        var btnGroup = qtyBtn.closest('.btn-group');

        update_quantity_btn(qtyBtn, quantity_revert);

        var errorElem = btnGroup;
        if (quantity_revert <= 0) {
            /* If old_quantity is 0, update_quantity_btn will
            hide the .spinner-form which contains btnGroup.
            So the shake effect and error popover wouldn't be visible
            to the user.
            We fix that by choosing a different target for the shake
            effect and popover. */
            errorElem = btnGroup
                .closest('.spinner-form')
                .siblings('.single-form');
        }

        attachErrorPopover(errorElem, error_msg);
    });
}

function jamCartHTMLIntoDOM(rendered_carts) {
    var scroll = $('.mini-cart-body').scrollTop();
    var height = $('.mini-cart-body').prop('scrollHeight');
    var $rendered_carts = $(rendered_carts);

    $('.mini-cart').html($rendered_carts.filter('.mini-cart').html());
    height = $('.mini-cart-body').prop('scrollHeight') - height;
    if (scroll !== 0) {
        $('.mini-cart-body').scrollTop(scroll + height);
    }

    $('.side-cart').html($rendered_carts.filter('.side-cart').html());

    $('.main-cart').html($rendered_carts.filter('.main-cart').html());

    $('.mobile-cart').html($rendered_carts.filter('.mobile-cart').html());

    // Used by Murchie's.
    $('.mini-cart-dropdown').html(
        $rendered_carts.filter('.mini-cart-dropdown').html()
    );

    var subTotalOrCount = $rendered_carts.filter('#cart-subtotal-or-count');

    if (subTotalOrCount) {
        $('.cart-subtotal-or-count').html(subTotalOrCount);
    } else {
        if (window.mc_settings.SHOP_CART_BUTTON_USES_DOLLAR_AMOUNT) {
            $('.cart-subtotal-or-count').html("$0.00");
        } else {
            $('.cart-subtotal-or-count').html("0");
        }
    }

    $('.tax-popover-link').popover();
    bindCartWidgets();
}

function bulk_add_btn_popover(content, title, msg_level) {
    /* add a popover <div> to any .bulk-add-btn in case that's
    what the user clicked to initiate cart changes and AJAX. */

    title = title || 'Success!';
    msg_level = msg_level || 'success';

    var popover = createErrorPopover(msg_level);
    var popoverTemplate = popover.prop('outerHTML');

    $('.bulk-add-btn.clicked')
        .popover({
            template: popoverTemplate,
            title: title,
            content: content,
            placement: "top",
            trigger: "focus"
        })
        .focus();
    $('.bulk-add-btn').removeClass('clicked');
}

function bind_note_widget(note_btn) {
    var id_within_container = note_btn.attr('data-id-within-container');
    var cart_type = note_btn.attr('data-cart-type');
    var cart_pk = note_btn.attr('data-cart-pk');
    var note_container = note_btn.closest(".note-container");

    var note_btn_widget = note_container.find(".note-btn-widget");
    var note_edit_container = note_container.find(".note-edit-container");
    var note_save = note_container.find(".note-save");
    var note_cancel = note_container.find(".note-cancel");
    var note_text = note_container.find(".note-text");
    var substitute_checkbox = note_container.find(".substitute-checkbox");

    var note_preview_container = note_container.find(".note-preview-container");
    var note_preview = note_container.find(".note-preview");
    var substitute_preview_checked = note_container.find(".substitute-preview-checked");
    var substitute_preview_unchecked = note_container.find(".substitute-preview-unchecked");

    function activate_note_widget(activate) {
        // Activate: Show editor stuff, hide preview stuff
        // Deactivate: Hide editor stuff, show preview stuff

        if (!activate) {
            note_edit_container.addClass("d-none");
            note_preview_container.removeClass("d-none");
            note_btn_widget.removeClass("d-none");
            note_text.blur();
            substitute_checkbox.blur();
        }
        var disabled = !activate;
        note_text.prop("disabled", disabled);
        note_save.prop("disabled", disabled);
        note_cancel.prop("disabled", disabled);
        substitute_checkbox.prop("disabled", disabled);
        if (activate) {
            note_edit_container.removeClass("d-none");
            note_preview_container.addClass("d-none");
            note_btn_widget.addClass("d-none");
            note_text.focus();
        }
    }

    note_btn.on('click', function (event) {
        event.preventDefault();
        activate_note_widget(true);
    });

    note_save.on('click', function (event) {
        event.preventDefault();

        var note = note_text.val();
        var substitute = substitute_checkbox.prop('checked');

        save_note(id_within_container, note, substitute, cart_type, cart_pk);
        activate_note_widget(false);
    });

    note_cancel.on('click', function (event) {
        event.preventDefault();
        activate_note_widget(false);
    });

    /*
    // WHY THIS IS COMMENTED OUT:
    // If you click "cancel", the text field's blur event fires first, so it saves, and hides
    // the cancel button, preventing the cancel button's click event from firing.
    //
    note_text.on('blur', function(e) {
        // Make sure event wasn't generated by us, which leads to 'too much recursion' errors:
        if(e.originalEvent){
            e.preventDefault();
            note_save.click();
        }
    });
    */

    note_text.on('keydown', function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            note_save.click();
        } else if (e.keyCode == 27) {
            e.preventDefault();
            note_cancel.click();
        }
    });
}



/***************************************************************
 * CART ACTIONS (GET WIDGET DATA + CALL AJAX + UPDATE WIDGETS) *
 ***************************************************************/

function save_note(id_within_container, note, substitute, cart_type, cart_pk, no_ajax) {
    /*
        Saves 'note' and 'substitute' fields of OrderItem via AJAX,
        and updates widgets with new values
    */

    if (!no_ajax) {
        // Patch the item with the new data via AJAX
        var data = {};
        if (note !== undefined) {
            data['note'] = note;
        }
        if (substitute !== undefined) {
            data['substitute'] = substitute;
        }
        patch_item(id_within_container, cart_type, cart_pk, data);
    }

    // String by which jQuery should filter DOM elements for this cart item
    var jfilter = '[data-id-within-container=' + id_within_container + ']';

    // Update any other note inputs and previews for this product on the page:
    if (note !== undefined) {
        var $note_inputs = $('input.note-text' + jfilter);
        var $note_previews = $('.note-preview' + jfilter);
        $note_inputs.val(note);
        $note_previews.text(note);
    }

    // Update any other substitute checkboxes and previews for this product on the page:
    if (substitute !== undefined) {
        var $substitute_checkboxes = $('.substitute-checkbox' + jfilter);
        var $substitute_previews_checked = $('.substitute-preview-checked' + jfilter);
        var $substitute_previews_unchecked = $('.substitute-preview-unchecked' + jfilter);
        $substitute_checkboxes.prop('checked', substitute);
        hide_elements($substitute_previews_checked, !substitute);
        hide_elements($substitute_previews_unchecked, substitute);
    }
}

function allow_all_subs(allow, cart_type, cart_pk) {
    /*
        Saves 'substitute' field of OrderItem via AJAX,
        and updates widgets with new values
    */

    // The endpoint we're sending this off to accepts the substitute as 'y', or 'n'
    var allow_data;
    if (allow) {
        allow_data = 'y';
    } else {
        allow_data = 'n';
    }

    var data = {};

    var items = window.serial_cart.items;
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        // No ajax, because we're going to do a single ajax call for all of them when we're done.
        save_note(item.id_within_container, undefined, allow,
            cart_type, cart_pk, true);
        data['substitute-' + item.unique_key] = allow_data;
    }

    cart_add(data);

}




/*
    refreshCartHTML, productPost, and refreshTimer:

    There are 2 major AJAX endpoints involved in updating cart item
    quantities. Each endpoint is called by a different function:
    - cart_add_endpoint (called by productPost via cart_add)
    - cartUrl (called by refreshCartHTML via getRenderedCarts)

    Calls to cart_add_endpoint update the cart data on the server and
    return success/error info.
    This call is fairly quick.

    Calls to cartUrl result in the server re-rendering the various
    representations of the cart (main cart, mini cart, side cart, etc).
    The rendered HTML is poked into the DOM, and various event listeners
    are reattached via bindCartWidgets().
    This is all fairly expensive.

    The purpose of refreshTimer is to avoid doing the expensive stuff
    until the user is finished clicking buttons.
    When we get a response from cart_add_endpoint, we wait
    refreshTimeDelay milliseconds before calling cartUrl.
    Each AJAX response resets the refreshTimer.

    In other words, if you hammer on a "+" button fast enough, the
    HTML widgets shouldn't update until after you've stopped for at
    least refreshTimeDelay milliseconds.
*/

function refreshCartHTML(callback, urlParams, data) {
    if (typeof data === "undefined") data = null;
    var response = data ? postRenderedCarts(urlParams, data) : getRenderedCarts(urlParams);
    response.done(function (rendered_carts) {
        jamCartHTMLIntoDOM(rendered_carts);
        $(document).trigger('cartRefreshEvent', { carts: rendered_carts });
        callback();
    });
    update_modal_buttons()
}

function productPost(data, cart_type, cart_pk) {

    if (cart_type) data['cart_type'] = cart_type;
    if (cart_pk) data['cart_pk'] = cart_pk;

    /* AJAX */
    var request = cart_add(data);

    if (refreshTimer) {
        clearTimeout(refreshTimer);
    }

    return request.done(function (msg) {

        if (msg.serial_cart) {
            window.serial_cart = msg.serial_cart;
        }

        if (msg.last && getObjSize(msg.successes) > 0) {
            /* NOTE: msg.last wasn't generated by our view; it was stuck on there by ajaxq.js.
            It means this was the last message in the queue.
            It's used here as a speed trick, so that we don't call refreshCartHTML
            if there are pending cart actions, since those will presumably return soon
            and trigger another expensive call to refreshCartHTML.
            It can be safely removed if we move away from using ajaxq. */

            let callback = function () {
                var msg_level = 'danger';
                if (msg.main_msg_lvl == 'Success') {
                    msg_level = 'success';
                } else if (msg.main_msg_lvl == 'Warning') {
                    msg_level = 'warning';
                }
                bulk_add_btn_popover(msg.main_msg, msg_level,
                    msg.main_msg_level);
            };
            const urlParams = window.isOneStep ? "?checkout=True" : "";
            refreshTimer = setTimeout(refreshCartHTML.bind(null, callback, urlParams), refreshTimerDelay);
        }

        var badKeys = Object.keys(msg.errors);
        for (var i = 0; i < badKeys.length; i++) {
            var uniqueKey = badKeys[i];

            var bad_quantity_revert = parseQuantity(msg.errors[uniqueKey]);

            var error_msg = msg.error_msgs[uniqueKey];
            roll_back_quantity_btns(uniqueKey, bad_quantity_revert, error_msg);
        }
    });
}

function add_product(uniqueKey, add_quantity, cart_type, cart_pk, note) {
    /* update widgets */
    update_quantity_btns_additive(uniqueKey, add_quantity, cart_type, cart_pk);

    /* AJAX */
    var data = {};
    data[uniqueKey] = add_quantity;
    if (typeof note !== "undefined") {
        var noteKey = "note-" + uniqueKey;
        data[noteKey] = note;
    }
    return productPost(data, cart_type, cart_pk);
}

function add_product_direct(uniqueKey, old_quantity, new_quantity, cart_type, cart_pk) {
    /* set product quantity from a known old value to a new value */

    /* update widgets */
    update_quantity_btns(uniqueKey, new_quantity, cart_type, cart_pk);

    /* AJAX */
    var data = {};
    data[uniqueKey] = parseQuantity(new_quantity - old_quantity);
    productPost(data, cart_type, cart_pk);
}

function save_quantity_inpt(inpt, old_quantity, cart_type, cart_pk) {
    var qtyBtn = inpt.closest('.quantity-btn');
    var uniqueKey = qtyBtn.attr('data-unique-key');
    var btnGroup = qtyBtn.closest('.btn-group');
    var new_quantity = parseQuantity(inpt.val());
    if (new_quantity) {
        qtyBtn.removeClass("edit-quantity");
        qtyBtn.siblings('.btn').show();
        btnGroup.removeAttr("style");

        add_product_direct(uniqueKey, old_quantity, new_quantity, cart_type, cart_pk);
    } else {
        qtyBtn.removeClass("edit-quantity");
        qtyBtn.siblings('.btn').show();
        btnGroup.removeAttr("style");
        var jfilter = '[data-unique-key=' + uniqueKey + ']';
        $('.quantity-btn' + jfilter).each(function (i, elem) {
            $(elem).html(old_quantity);
        });
    }
}

function delete_item(id_within_container, unique_key, cart_type, cart_pk) {
    var unique_key_counts = serial_cart_get_unique_key_counts(
        window.serial_cart);

    if (unique_key_counts[unique_key] > 1) {
        unique_key = 'id-' + id_within_container;
    }

    var data = {};
    data['delete-id-' + id_within_container] = true;
    update_quantity_btns(unique_key, 0);
    productPost(data, cart_type, cart_pk);
}

function update_modal_buttons() {
    $('.productbutton-modal').each(function() {
        var unique_key = $(this).data("mc-unique-key");
        const items = window.serial_cart.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].unique_key === unique_key) {
                $(this).removeClass('btn-outline-primary')
                $(this).addClass('btn-primary')
                $(this).html('ADDED TO CART')
                return
            }
        }
        
        $(this).addClass('btn-outline-primary')
        $(this).removeClass('btn-primary')
        $(this).html('ADD TO CART')
    })
}

/* TODO: [] The CartSerializer needs to provide the subcart_name for the current product.
 */
export function empty_cart() {
    var data = {};
    var unique_key_counts = serial_cart_get_unique_key_counts(window.serial_cart);

    var items = window.serial_cart.items;
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var id_within_container = item.id_within_container;
        var unique_key = item.unique_key;

        data['delete-id-' + id_within_container] = true;

        if (unique_key_counts[unique_key] > 1) {
            unique_key = 'id-' + id_within_container;
        }

        /* update widgets */
        update_quantity_btns(unique_key, 0);
    }

    /* AJAX */
    productPost(data);
}


// /*********************
//  * ON DOCUMENT READY *
//  *********************/
// $(document).ready(function () {

//     window.dataLayer = window.dataLayer || [];

//     render_serial_cart(window.serial_cart);

//     bindCartWidgets();

//     window.console_cart = new ConsoleCart();
//     $(document).trigger("consoleCartReady");
//     if (window.mc_settings.SHOP_PREVIOUS_PAGE_REFRESH) {
//         window.addEventListener('pageshow', function (event) {
//             console_cart.refreshCartHTML(function () {
//                 console.log("Refreshed Cart");
//             });
//         }, false);

//     }

// });
