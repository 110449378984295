/*
 *  Fave app favourites js handlers
 */


// We are expecting a boolean variable, window.mc_use_fave_for_fave, to be set.
// It should be true if this page is the My Favourites page.

function update_favourites_button($button, is_favourite){
    if (is_favourite){
        $button.removeClass('btn-favourite-product');
        $button.addClass('btn-unfavourite-product');
        if ($button.text()) {
            $button.contents().get(1).nodeValue = " Saved as Favourite";
        }
        if ($button.attr('title')) {
            $button.attr('title', 'Remove from favourites')
        }
    } else {
        if (window.mc_use_fave_for_fave) {
            $button.closest('.product-thumb').remove();
        } else {
            $button.removeClass('btn-unfavourite-product');
            $button.addClass('btn-favourite-product');
            if ($button.text()) {
                $button.contents().get(1).nodeValue = " Save as Favourite";
            }
            if ($button.attr('title')) {
                $button.attr('title', 'Add to favourites')
            }
        }
    }
}

function toggle_favourites_button($button){
    // If the product *is* a favourite, the button's action will be to *un*-favourite it.
    var is_favourite = $button.hasClass('btn-unfavourite-product');
    var toggled_is_favourite = !is_favourite;
    update_favourites_button($button, toggled_is_favourite);
}

function update_favourites_button_from_wishlist($button, wishlist){
    var unique_key = $button.attr('data-unique_key');
    var is_favourite = $.inArray(unique_key.toString(), wishlist) >= 0;
    update_favourites_button($button, is_favourite);
}

function update_favourites_buttons_from_wishlist(wishlist){
    var buttons = $('.btn-favourite');
    var i = 0;
    for (i = 0; i < buttons.length; ++i){
        var $button = $(buttons[i]);
        update_favourites_button_from_wishlist($button, wishlist);
    }
}

function bind_favourites_buttons(){
    bound_btns = [];
    $('.btn-favourite').each(function(){
        var btn = this;

        /* Secret attribute so we can call bind_favourites_buttons
        multiple times without adding multiple event listeners */
        if(btn._favourites_buttons_bound) return;
        btn._favourites_buttons_bound = true;

        /* For debugging */
        bound_btns.push(btn);

        $(btn).click(function( event ){
            event.preventDefault();

            var $button = $(this);
            $button.addClass('');

            var unique_key = $button.attr('data-unique_key');
            var data = {
                "unique_key": unique_key
            };

            toggle_favourites_button($button);

            // The following AJAX call times out when the wishlist gets large.
            // Since we're only making this call when we click the button for a specific
            // product, we could simply change it to only pass back a boolean value
            // indicating whether or not the change was successful.
            // For now it was much easier to just toggle the button's CSS immediately.
            // We still attempt to update it if we get an AJAX response, but if that
            // times out, we have implicitly assumed it was successful.
            var request = $.ajaxq('MightyQ:faves', {
                url: fave_add_endpoint,
                type: "POST",
                headers: {'X-CSRFToken': window.csrf_token},
                data: data
            });
            request.done(function(msg) {
                wishlist = msg.wishlist;
                $(".btn-favourite[data-unique_key='" + unique_key + "']").each(function() {
                    update_favourites_button_from_wishlist($(this), wishlist);
                })
            });
            $button.blur();
        });
    });

    return bound_btns;
}

$(document).ready(function(){
  var wishlist = wishlistOnLoad;
  update_favourites_buttons_from_wishlist(wishlist);
  bind_favourites_buttons();
});

$(document).on('cartRefreshEvent', function(event, data){
  const wishlistStr = $('<div/>').html($.parseHTML(data.carts)).find('input[name="new-wishlist"]').val();
  const newWishlist = JSON.parse(wishlistStr);
  update_favourites_buttons_from_wishlist(newWishlist);
  bind_favourites_buttons();
});

