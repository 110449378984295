import {Toast, Modal} from 'bootstrap';


export function showAddToListMsg(key, page, reload_page) {

    var idAddListCSS = page == 'thumbnail' ?
        ".add-to-list-btn[data-unique-key='"+key+"']" : "#id_product_actions";

    var toastElem = $('#addToListToast');
    var productName = $(idAddListCSS).attr('data-product-name');
    if (productName) {
        toastElem.find('.toast-body').html('Added '+productName+' to the list');
    }
    var toast = new Toast(toastElem);
    toast.show();
}

export function openShoppingListDialog(key) {
    const listModal = new Modal('#addToListModal');
    $('#addToListModal').on('submit', function(event){
        event.preventDefault();
        var list_name = event.target.elements.list_name.value;
        if (list_name != "") {
            createShoppingList(key, 'thumbnail', list_name);
        }
    });
    listModal.show();
}

export function createShoppingList(uniqueKey, page, list_name) {
    /* Creates a new shopping list via AJAX, and adds a product to it
    based on uniqueKey.
    The new shopping list is also set as the "default" (that is, currently
    selected) list for this user. */

    var idAddListCSS = page == 'thumbnail'?
        "#id-product-footer-"+uniqueKey: "#id_product_actions";

    if (list_name != '') {
        var csrftoken = window.csrf_token;
        var data = 'name=' + list_name + '&default=true';

        $.ajax({
            type: "POST",
            url: shopping_list_create_endpoint,
            headers: {'X-CSRFToken': csrftoken},
            data: data,
            success: function(resp) {
                if (page=='menu') {
                    location.reload();
                } else {
                    var $addToListBtn = $('.add-to-list-btn[data-unique-key='+uniqueKey+']');

                    /* Add the add-btn class so cart_add.js will hook the button */
                    $addToListBtn.addClass('add-btn');
                    $addToListBtn.attr('data-cart-type', 'shoppinglist');
                    $addToListBtn.attr('data-cart-pk', 'default');
                    window.console_cart.bindCartWidgets();

                    var isPack = $addToListBtn.attr("data-is-pack") === "true";
                    if (page=='thumbnail') {
                        if (!isPack) {
                            showAddToListMsg(uniqueKey, 'thumbnail', true);
                            window.console_cart.add_product(uniqueKey, 1, resp.cart_type, resp.cart_pk);
                        } else {
                            // Reload the page to update the list dropdown and data attributes.
                            location.reload()
                        }
                    }

                }
            },
        }).fail(function(error) {
            if (error.status == 412) {
                location.reload();
            }
            else {
                alert( "Cannot add to shopping list. \n Error: " + error.statusText);
            }
            setTimeout(function() {
                $(idAddListCSS).unblock()
            }, 1000);
        });
    }
}


function setDefaultList(url) {
    /* Sets default list via AJAX, but forces you to provide the
    endpoint's URL yourself (???) and forcibly reloads the page
    on success, instead of just manipulating the DOM.

    Used by shopping_list_table.html.

    Maybe TODO: replace this function with setDefaultListAjax, which
    is a bit saner. */

    return $.ajax({
        type: "GET",
        url: url,
        success: function(r) {
            location.reload();
        },
    }).fail(function() {
        alert( "Cannot set default shopping list." );
    });
}

function updateDefaultList(id){
    /* After successfully changing the default list, call this function
    to update various DOM elements indicating which list is the default. */

    /* Check the radio button for the new default list */
    var radios = $('.shoppinglist_radio_btn');
    var radio = radios.filter('[data-shoppinglist_id=' + id + ']');
    radio.prop('checked', true);
}

function setDefaultListAjax(id){
    /* Sets the default shopping list via AJAX. */
    var url = mc_urls.shopping_list_set_default + id + '/';
    return $.ajax({
        type: "GET",
        url: url,
        success: function(r) {
            /* Update DOM stuff to reflect the new default. */
            updateDefaultList(id);
        },
        contentType:"application/json; charset=utf-8",
        dataType : "json",
    }).fail(function() {
        alert( "Cannot set default shopping list." );
    });
}

export function deleteShoppingList(url, id, reload) {
    var deleteModal = new Modal('#deleteListModal');
    console.log({url});
    var deleteBtn = $('#deleteListButton');
    deleteBtn.on('click', function() {
            $.ajax({
                type: "POST",
                url: url,
                data: $("#id-shopping-list-delete-form").serialize(),
                success: function(r) {
                    if (reload) {
                        location.reload();
                    } else {
                        window.open("/shopping_lists/", "_self");
                    }
                },
            }).fail(function(error) {
                location.reload();
            }); 
    });
    deleteModal.show();
}


export function initShoppingLists() {
    $('#id-shopping-list-form-new').each(function(i, elem) {
        /* This is the form at the bottom of the "Lists" dropdown menu at the
        top of the page. */

        if(!elem._shopping_cart_onsubmit_registered){
            elem._shopping_cart_onsubmit_registered = true;

            $(elem).on('submit', function(event) {
                event.preventDefault();
                var list_name = this.list_name.value;
                createShoppingList('new', 'menu', list_name);
            });
        }
    });
    
    $('.shoppinglist_radio_btn').on('change', function(event){
            /* When you click the radio buttons in the "Lists" dropdown menu,
            it updates the current default shopping list via AJAX */
            var id = this.getAttribute('data-shoppinglist_id');
            setDefaultListAjax(id);
    });
}


